// All Api Endpoints
const VERSION = 'v1';
const API_VERSION = `api/${VERSION}`;
// export const BASE_SERVER_URI = `http://localhost:4000/${API_VERSION}`;
export const BASE_SERVER_URI = `https://api.amfeza.com/${API_VERSION}`;
// export const BASE_SERVER_URI = `https://api-roverdrifters-upkd.onrender.com/${API_VERSION}`;
const joinPathWIthBaseApp = (path) => BASE_SERVER_URI + `/${path}`;
const tours = {
    getAllTours: joinPathWIthBaseApp('tours'),
};
const destinations = {
    getAllDestinations: joinPathWIthBaseApp('countries'),
    getTopDestinations: joinPathWIthBaseApp('countries'),
};
const types = {
    getAllTypes: joinPathWIthBaseApp('types'),
};
const packages = {
    allPackages: joinPathWIthBaseApp('packages'),
};
const hotels = {
    getAllHotels: joinPathWIthBaseApp('hotels'),
};
const banners = {
    home: {
        url: 'https://picsum.photos/v2/list?page=1&limit=6',
    },
};

const auth = {
    register: joinPathWIthBaseApp('auth/signup'),
    sendOtp: joinPathWIthBaseApp('auth/sendotp'),
    updateSignUpInfo: joinPathWIthBaseApp('auth/update-signup-info'),
    checkOtp: joinPathWIthBaseApp('auth/checkotp'),
    login: joinPathWIthBaseApp('auth/login'),
    updateProfile: joinPathWIthBaseApp('auth/update-profile'),
};



export { auth, banners, destinations, hotels, packages, tours, types };
