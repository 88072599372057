"use client"
import {useSearchParams, usePathname } from "@/index";
import {useMemo} from "react";
const useSearchQuery = () => {
    const searchParams = useSearchParams();
    return useMemo(() => searchParams, [searchParams]);
};

const useSearchName = (name) => {
    const searchParams = useSearchQuery();
    return useMemo(() => searchParams.get(name), [name, searchParams]);
}
const useQueryPathname = () => {
    const pathname = usePathname();
    return useMemo(()=>pathname,[pathname]);
}
const handleQuery = (method,queries, pathname, searchQuery) => {
    const params = new URLSearchParams(searchQuery);
    if (typeof queries !== typeof []) {
        queries = [queries];
    };
// console.log("appnended :: ",method,queries) 
    queries.forEach(query => {
        method == "set" ? params[method](query[0] ,query[1])  : params[method](query[0]);
    });

    history.replaceState(null, "", `${pathname}?${params.toString()}`);
};

export {handleQuery,useSearchName,useSearchQuery,useQueryPathname};