'use client';

import ActionButton from '@/components/ActionButton';
import ChevronDown from '@/components/svgs/ChevronDown';
import CrossIcon from '@/components/svgs/CrossIcon';
import { sendOTP, updateSignUpInfo } from '@/services/operations/auth';
import styles from '@/styles/authform.module.css';
import clsx from 'clsx';
import { useState } from 'react';
const Signup = ({
    setIsSignUp,
    otpVerification,
    setOtpVerification,
    setVerifiedUserId,
    verifiedUserId,
    setOpenAuthModal
}) => {
    const [userDetails, setUserDetails] = useState(null);
    const [loader, setLoader] = useState(false);
    const [OTPSent, setOTPSent] = useState(false);
    const [error, setError] = useState(null);

    const [verifyEmail, setVerifyEmail] = useState(false);
    const handleUserDetails = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setError(null);
        setUserDetails((prev) => ({ ...prev, [name]: value }));
    };

    console.log(userDetails);
    // console.log(otpVerification);
    // console.log(verifiedUserId);
    const submitForm = async (e) => {
        e.preventDefault();
        if (userDetails.password !== userDetails.confirmPassword) {
            setError({
                message: 'Password and Confirm Password are not matching!',
            });
        }
        const formData = {
            userId: verifiedUserId,
            ...userDetails,
        };
        console.log("THIS IS FORM DATA :: ",formData);
        // try {
        //     const res = await apiConnector(
        //         'POST',
        //         auth.updateSignUpInfo,
        //         formData,
        //     );
        //     console.log(res);
        //     if (!res.data.success) {
        //         throw new Error('Somthing went wrong while registering ...');
        //     }
        //     console.log('Registerd Successfully Chech DB');
        // } catch (error) {
        //     console.log(error);
        // }
        await updateSignUpInfo(formData, setIsSignUp, setLoader, setError);
    };

    const sendOtp = async (e) => {
        e.preventDefault();
        // try {
        //     const res = await apiConnector('POST', auth.sendOtp, {
        //         email: userDetails.email,
        //         name: userDetails.firstName + userDetails.lastName,
        //     });
        //     console.log(res);
        //     if (!res.data.success) {
        //         throw new Error('Somthing went wrong while registering ...');
        //     }
        //     setSendOtpVarified(true);
        //     console.log('Registerd Successfully Chech DB');
        // } catch (error) {
        //     console.log(error);
        // }
        await sendOTP(
            userDetails.email,
            setOTPSent,
            setLoader,
            setError,
            setOtpVerification,
            setIsSignUp,
            setVerifiedUserId,
        );
        setOpenAuthModal(false);
    };
    return (
        <>
            <div className="flex gap-x-4 items-center px-6 mb-3 mt-5">
                <div
                    onClick={() => setIsSignUp(false)}
                    className="w-[30px] h-[30px] flex items-center justify-center hover:bg-gray-50 cursor-pointer rounded-full"
                >
                    <ChevronDown className={'rotate-90 '}></ChevronDown>
                </div>

                <h1 className="text-left text-lg  text-gray-800">
                    Register your self
                </h1>
            </div>
            <p className="text-gray-600 text-sm px-8 leading-5 mb-3 font-giest-light">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis
                exercitationem architecto accusamus.?
            </p>
            <form
                className={clsx('mt-4 text-sm', styles.auth_form)}
                onSubmit={otpVerification.isVerified ? submitForm : sendOtp}
            >
                {otpVerification.isVerified && (
                    <>
                        <input
                            type="text"
                            placeholder="First Name"
                            required
                            className={styles.text_input}
                            onChange={handleUserDetails}
                            name="nickName"
                            value={userDetails?.nickName || ''}
                        />
                        {/* <input
                            type="text"
                            placeholder="Last Name"
                            required
                            className={styles.text_input}
                            onChange={handleUserDetails}
                            name="lastName"
                            value={userDetails?.lastName || ''}
                        /> */}

                        <input
                            type="password"
                            placeholder="Password"
                            required
                            className={styles.text_input}
                            onChange={handleUserDetails}
                            name="password"
                            value={userDetails?.password || ''}
                        />
                        <input
                            type="text"
                            placeholder="Confirm password"
                            required
                            className={styles.text_input}
                            onChange={handleUserDetails}
                            name="confirmPassword"
                            value={userDetails?.confirmPassword || ''}
                        />
                    </>
                )}
                {!otpVerification.isVerified && (
                    <input
                        type="email"
                        placeholder="Your email"
                        required
                        className={styles.text_input}
                        onChange={handleUserDetails}
                        name="email"
                        value={userDetails?.email || ''}
                    />
                )}
                {error && (
                    <div className="py-2">
                        <div className="bg-red-500 text-white rounded-lg py-2 px-4">
                            <CrossIcon className="text-white float-left"></CrossIcon>
                            <p className="text-center">{error?.message}</p>
                        </div>
                    </div>
                )}
                {!error && (
                    <ActionButton className={styles.submit_button}>
                        {otpVerification.isVerified && 'Register'}
                        {!otpVerification.isVerified && 'Send OTP'}
                    </ActionButton>
                )}
            </form>
        </>
    );
};

export default Signup;
