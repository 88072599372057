"use client";
import { useQuery } from "react-query";
import { apiConnector } from "@/services/apiconnector";
import { BASE_SERVER_URI } from "@/services/apis";
import Cookies from "js-cookie";

const fetchUserDetails = async () => {
    // const getToken = Cookies.get("amf._login.token");
    try {
        const userdata = await apiConnector("GET", `${BASE_SERVER_URI}/me`);

        // {
        //     "amf._login.token": getToken
        // },
        //     {
        //         Authorization: "Bearer " + getToken
        //     }


        console.log("USER COOKIE :: IS HERE :: ", Cookies.get("amf._login.token"));
        // console.log()
        if (!userdata?.data?.success) {
            console.log(userdata?.data);
            throw new Error(userdata?.data.message)
        };
        return userdata?.data?.data.user;
    } catch (err) {
        throw new Error(err.message);
    }
};

const useUserDetails = () => {
    const { data: userDetails, error, isLoading, refetch } = useQuery({
        queryKey: ["userDetails"],
        queryFn: fetchUserDetails,
        staleTime: Infinity, // Cache for 5 minutes
        retry: 1, // Retry once on failure
    });

    return { userDetails, isLoading, error, refetch };
};

export default useUserDetails;
