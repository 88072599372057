import React from "react";

function useMediaQuery(query) {
    const [matches, setMatches] = React.useState(false);

    React.useEffect(() => {
        const matchQueryList = window.matchMedia(query);
        // Initial check
        setMatches(matchQueryList.matches);

        // function handleChange(e) {
        //     setMatches(e.matches);
        // }

        // matchQueryList.addEventListener("change", handleChange);
        // return () => {
        //     matchQueryList.removeEventListener("change", handleChange);
        // };
    }, [query]);

    return matches;
}

export default useMediaQuery;
