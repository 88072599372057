"use client";

import { clsx } from "@/index.js";
import { createPortal } from "react-dom";
import { useEffect, useRef, useState } from "react";
import "./sheetstyle.css";

const BottomSheet = ({
  isOpen,
  setIsOpen,
  height = "50vh",
  children,
  isBlockMode,
  className,
  isOpacityEffect,
  blockSideClick,
  hasBlockScreen = true,
  hasSnapPoints = false,
  scrollableChildrenRef,
  style,
}) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);
  const [isDragging, setIsDragging] = useState(false);
  const sheetChildren = useRef(null);
  const [startY, setStartY] = useState(0);
  const [startHeight, setStartHeight] = useState(parseInt(height));
  const [sheetHeight, setSheetHeight] = useState(parseInt(height));

  const closeModal = () => {
    setIsOpen(false);
  };

  const updateHeight = (newHeight) => {
    setSheetHeight(newHeight);
    if (newHeight > 95) {
      document.querySelector(".amf-modal-sheet").classList.add("fullscreen");
      if (scrollableChildrenRef.current)
        scrollableChildrenRef.current.style.overflow = "auto";
    } else {
      //   console.log(scrollableChildrenRef.current.scrollTop);
      if (scrollableChildrenRef.current)
        if (Math.floor(scrollableChildrenRef.current.scrollTop) === 0)
          scrollableChildrenRef.current.style.overflow = "hidden";
      document.querySelector(".amf-modal-sheet").classList.remove("fullscreen");
    }
  };

  const dragStart = (e) => {
    if (Math.floor(scrollableChildrenRef.current.scrollTop) === 0) {
      setIsDragging(true);
      setStartY(e.pageY || e.touches?.[0].pageY);
      setStartHeight(sheetHeight);
    }
  };

  const dragging = (e) => {
    if (isDragging) {
      const delta = startY - (e.pageY || e.touches?.[0].pageY);
      const newHeight = startHeight + (delta / window.innerHeight) * 200;
      updateHeight(newHeight);
    }
  };

  const dragStop = () => {
    setIsDragging(false);
    const currentHeight = sheetHeight;
    if (currentHeight < 25) {
      updateHeight(20);
    } else if (currentHeight > 75) {
      updateHeight(100);
    } else {
      updateHeight(50);
    }
  };

  useEffect(() => {
    if (hasSnapPoints && sheetChildren.current) {
      if (isDragging) {
        sheetChildren.current.addEventListener("mousemove", dragging);
        sheetChildren.current.addEventListener("mouseup", dragStop);
        sheetChildren.current.addEventListener("touchmove", dragging);
        sheetChildren.current.addEventListener("touchend", dragStop);
      } else {
        sheetChildren.current.removeEventListener("mousemove", dragging);
        sheetChildren.current.removeEventListener("mouseup", dragStop);
        sheetChildren.current.removeEventListener("touchmove", dragging);
        sheetChildren.current.removeEventListener("touchend", dragStop);
      }
    }

    return () => {};
  }, [isDragging, sheetHeight, hasSnapPoints, sheetChildren.current]);

  useEffect(() => {
    if (hasSnapPoints) {
      const body = document.body;
      if (isOpen) {
        body.style.overflow = "hidden !important";
        setSheetHeight(50); // Reset to default height when opening
      } else {
        body.style.overflow = "auto";
      }
    }
  }, [isOpen, hasSnapPoints]);

  return (
    // <div className="amf-modal-sheet-container z-[99999999]">
    <>
      {mounted &&
        createPortal(
          <>
            {hasBlockScreen && (
              <div
                className={clsx(
                  "amf-modal-bg-overlay",
                  isOpen && "open",
                  !isOpen && "close"
                )}
                onClick={!blockSideClick ? closeModal : () => {}}
              ></div>
            )}
            <div
              className={clsx(
                `amf-modal-sheet`,
                isOpen && "open",
                !isOpen && "close",
                isBlockMode && "block-mode",
                isOpacityEffect && "opacity-effect",
                className
              )}
              style={{
                "--amf-modal-background-color": "white",
                "--height": `${sheetHeight}dvh`,
                ...style,
              }}
              ref={sheetChildren}
              // onMouseDown={hasSnapPoints && dragStart}
              // onTouchStart={hasSnapPoints && dragStart}
            >
              {children}
            </div>
          </>,
          typeof window !== "undefined" && window.document.body
        )}
    </>
    // </div>
  );
};

export default BottomSheet;
