"use client";

import { useSearchName } from "@/hooks/searchParams";
import { apiConnector } from "@/services/apiconnector";
import { BASE_SERVER_URI } from "@/services/apis";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useEffect, useState } from "react";
import PrimeryHeading from "../core/PrimeryHeading";
import FoodIcon from "../svgs/FoodIcon";
import FootPrint from "../svgs/FootPrint";
import MapChartIcon from "../svgs/MapChartIcons";
import StayIcon from "../svgs/StayIcon";
import AuthencatedNavbar from "./AuthencatedNavbar";
import UnAuthencatedNavbar from "./UnAuthencatedNavbar";
import useUserDetails from "@/hooks/useUserDetails";

const Navbar = () => {
  const [userData, setUserData] = useState(null);
  const { userDetails } = useUserDetails();
  const success = useSearchName("success");
  const type = useSearchName("type");
  const message = useSearchName("message");

  //   const getUserDetails = useCallback(async () => {
  //     try {
  //       const userdata = await apiConnector("get", `${BASE_SERVER_URI}/me`);
  //     //   console.log(userdata);
  //       if (!userdata?.data?.success) throw Error(userdata?.data.message);
  //       setUserData(userdata?.data?.data.user);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }, []);

  useEffect(() => {
    if (userDetails) {
      setUserData(userDetails);
    }
  }, [userDetails]);

  return (
    <nav className="bg-white sticky top-0 z-[1000] navbar max-w-maxContent mx-auto">
      <div className="flex items-center h-[60px] sm:h-[80px] justify-between relative">
        <Link
          href={"/"}
          className="h-full w-fit px-4 flex items-center justify-center"
        >
          <Image
            src={"/profile/amfeza_logo.png"}
            width={120}
            height={60}
            className="w-[100px]"
            alt="Amfeza - Tours | Amfeza - Stays | Top Tours | top trending Tours | Best Amfeza Tours "
          ></Image>
        </Link>
        <ul className="hidden gap-x-4 md:flex">
          <NavbarBadge
            name={"Where To Go"}
            Icon={<MapChartIcon className="w-[16px]"></MapChartIcon>}
            fill={"#ebf2e2"}
          >
            {/* <WhereToGoDropDown></WhereToGoDropDown> */}
          </NavbarBadge>
          <NavbarBadge
            name={"Where To Eat"}
            Icon={<FoodIcon className="w-[18px]"></FoodIcon>}
            fill={"#f8f0dc"}
          ></NavbarBadge>
          <NavbarBadge
            name={"Places To Stay"}
            Icon={<StayIcon className="w-[18px]"></StayIcon>}
            fill={"#e7edef"}
          ></NavbarBadge>
          <NavbarBadge
            name={"What To Do"}
            Icon={<FootPrint className="w-[18px]"></FootPrint>}
            fill={"#eee9f5"}
          ></NavbarBadge>
        </ul>

        {!userData && (
          <UnAuthencatedNavbar setUserData={setUserData}></UnAuthencatedNavbar>
        )}
        {userData && (
          <AuthencatedNavbar
            userData={userData}
            setUserData={setUserData}
          ></AuthencatedNavbar>
        )}
      </div>
    </nav>
  );
};

const NavbarBadge = ({ name, fill, Icon = <></>, className, children }) => {
  return (
    <li
      className={
        "px-3 py-[6px] text-gray-700 rounded-full flex gap-x-2 items-center basis-full relative min-w-fit" +
        className
      }
      style={{ background: fill }}
    >
      {Icon}
      <span className="font-giest-regular text-[13px] min-w-fit">{name}</span>

      {children}
    </li>
  );
};

const WhereToGoDropDown = () => {
  return (
    <div className="absolute w-[700px] max-w-maxContentTab bg-white rounded-xl drop-shadow-xl -bottom-[390px] min-h-[380px] -translate-x-1/3 border">
      <PrimeryHeading className={"!text-[20px] px-6"}>
        Find Tours
      </PrimeryHeading>
    </div>
  );
};

// const fn = () => (
//   <div className="flex gap-x-4 justify-between py-[.6rem] md:py-3 items-center px-4">
//     <div className="flex gap-x-4 items-center">
//       {/* Hamburger Menu */}
//       <svg
//         width="20"
//         height="20"
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 24 24"
//         fill="currentColor"
//         class="ui-svg-inline md:hidden"
//       >
//         <path d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"></path>
//       </svg>
//       <Link href={"/"}>
//         <Image
//           src={"/profile/amfeza_logo.png"}
//           height={30}
//           width={110}
//           alt="logo_img"
//           // className="h-[20px] w-auto"
//         ></Image>
//       </Link>
//     </div>

//     <div className="flex gap-x-4 items-center ">
//       <SearchIcon></SearchIcon>

//       {/* Favoourite Icons/ heart Icon */}
//       <svg
//         stroke="currentColor"
//         fill="currentColor"
//         stroke-width="0"
//         viewBox="0 0 1024 1024"
//         height="1.25em"
//         width="1.25em"
//         xmlns="http://www.w3.org/2000/svg"
//         className="cursor-pointer"
//       >
//         <path d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z"></path>
//       </svg>

//       {/* User Icons */}
//       <svg
//         version="1.0"
//         xmlns="http://www.w3.org/2000/svg"
//         width=".79em"
//         height="1em"
//         viewBox="0 0 512.000000 512.000000"
//         preserveAspectRatio="xMidYMid meet"
//         className="text-xl"
//       >
//         <g
//           transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
//           fill="#000000"
//           stroke="none"
//         >
//           <path
//             d="M2349 4835 c-347 -66 -643 -266 -827 -559 -241 -383 -250 -867 -24
// -1260 251 -436 748 -676 1245 -601 480 72 880 432 1002 901 89 344 30 696
// -168 992 -171 256 -417 428 -726 509 -121 32 -380 41 -502 18z m436 -329 c265
// -73 480 -254 595 -499 60 -128 83 -237 83 -387 -1 -133 -18 -219 -67 -340
// -101 -252 -339 -459 -611 -532 -115 -31 -336 -30 -450 1 -274 74 -510 279
// -611 531 -49 121 -66 207 -67 340 -1 243 81 453 242 624 132 139 299 233 481
// 272 87 18 323 12 405 -10z"
//           />
//           <path
//             d="M2385 2074 c-16 -2 -73 -9 -125 -15 -295 -33 -638 -151 -887 -307
// -357 -221 -617 -537 -732 -889 -71 -214 -92 -463 -46 -525 30 -40 86 -68 137
// -68 93 0 158 81 158 196 0 140 58 353 133 486 165 294 443 528 791 666 593
// 235 1304 170 1811 -166 363 -240 576 -578 603 -958 6 -82 14 -120 28 -145 61
// -104 209 -107 269 -4 30 51 33 133 9 293 -109 737 -827 1332 -1722 1427 -123
// 13 -348 18 -427 9z"
//           />
//         </g>
//       </svg>
//     </div>
//   </div>
// );
export default Navbar;
