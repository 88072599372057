import Cookies from 'js-cookie';
import Image from 'next/image';
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './menu.css';
import Profile from './Profile';
const AuthencatedNavbar = ({ userData, setUserData }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const openMenu = () => setIsMenuOpen(true);
    const closeMenu = () => setIsMenuOpen(false);
    console.log(Cookies.get("amf._login.token"));
    return (
        <>
            <div
                className="flex items-center justify-center py-1 md:py-2 px-1 md:px-3 border rounded-full cursor-pointer mr-3"
                onClick={openMenu}
            >
                <Image
                    loader={() => userData?.profile?.image}
                    src={userData?.profile?.image}
                    width={30}
                    height={30}
                    className="rounded-full cursor-pointer bg-gray-400"
                    alt={userData?.name}
                ></Image>
                {/* <p className="text-sm mt-[3px] text-center hidden md:block">
                Welcome! {userData?.firstName}
            </p> */}
                {/* menu bar */}
                <button>
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        height="20px"
                        width="20px"
                        className="text-gray-600 mx-3 md:ml-3 "
                    >
                        <path
                            fill="none"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="32"
                            d="M80 160h352M80 256h352M80 352h352"
                        />
                    </svg>
                </button>
            </div>
            <CSSTransition
                in={isMenuOpen}
                classNames={'menu'}
                timeout={800}
                unmountOnExit
            >
                <Profile
                    userData={userData}
                    closeMenu={closeMenu}
                    setUserData={setUserData}
                ></Profile>
            </CSSTransition>
        </>
    );
};

export default AuthencatedNavbar;
