import Cookies from 'js-cookie';
import { apiConnector } from '../apiconnector';
import { auth } from '../apis';

const checkOTP = async (email, otp, setOtpVerification) => {
    try {
        console.log(email, otp);
        const res = await apiConnector('POST', auth.checkOtp, { email, otp });
        console.log(res);
        if (!res.data?.success) throw Error('Otp Check Failed');
        setOtpVerification((prev) => ({
            ...prev,
            isVerified: true,
            otp: otp,
            email,
        }));
    } catch (error) {
        console.log('Somthing Went wrong while checking OTP');
    }
};
const sendOTP = async (
    email,
    setOTPSent,
    setLoading,
    setError,
    setOtpVerification,
    setIsSignUp,
    setVerifiedUserId,
) => {
    setLoading(true);
    try {
        const sendOtpResponse = await apiConnector('POST', auth.sendOtp, {
            email,
        });
        console.log(sendOtpResponse);
        // console.log(
        //     !sendOtpResponse.data?.success &&
        //         sendOtpResponse.data?.errorCode === 'USER_OTP_VERIFIED',
        // );

        if (
            !sendOtpResponse.data?.success &&
            sendOtpResponse.data?.errorCode === 'USER_OTP_VERIFIED'
        ) {
            setLoading(false);
            console.log('Entered :: ');
            setVerifiedUserId(sendOtpResponse?.data?.userId);
            setOtpVerification((prev) => ({
                ...prev,
                isVerified: true,
                isOpen: false,
            }));
            setError({ message: sendOtpResponse.data.message });
            return;
        }

        if (
            !sendOtpResponse.data?.success &&
            sendOtpResponse.data?.errorCode === 'USER_ALREADY_EXISTS'
        ) {
            setLoading(false);
            setIsSignUp(false);
            setError({ message: sendOtpResponse.data.message });

            // setNotice()
        }

        if (!sendOtpResponse.data?.success) {
            setLoading(false);
            throw Error('Unable to Send OTP. please try again.');
        }
        setOTPSent(true);
        setLoading(false);
        setOtpVerification((prev) => ({
            ...prev,
            isOpen: true,
            isVerified: false,
            handlerFn: async (otp) =>
                await signUp(email, otp, setOtpVerification, setVerifiedUserId),
        }));
    } catch (error) {
        setOTPSent(false);
        setLoading(false);
        setError({ message: error });
    }
};
const signUp = async (email, otp, setOtpVerification, setVerifiedUserId) => {
    try {
        const response = await apiConnector('POST', auth.register, {
            email,
            otp,
        });
        console.log(response);

        if (!response.data?.success) {
            throw Error("Could'n sign up user there have some problem.");
        }
        setOtpVerification((prev) => ({
            ...prev,
            isVerified: true,
            email,
            otp: null,
            handlerFn: () => { },
        }));
        setVerifiedUserId(response?.data?.userId);
        return;
    } catch (error) {
        console.log(error);
    }
};
const updateSignUpInfo = async (formData, setIsSignUp, setLoader, setError) => {
    const payload = {
        nickName: formData.nickName,
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
        userId: formData.userId,
    };
    console.log(payload);
    setLoader(true);
    try {
        const response = await apiConnector(
            'POST',
            auth.updateSignUpInfo,
            payload,
        );
        console.log(response);
        if (!response.data?.success) {
            throw Error(response.data?.errorMessage || response.data?.errorCode);
        }
        setLoader(false);
        setIsSignUp(false);
        setError(null);
        // console.log(Cookies.get("loginToken"));
        // Cookies.set("loginToken", response.data?.token);
    } catch (error) {
        setLoader(false);
        setError({ message: error.message, error: error });
        console.log(error);
    }
};

const login = async (
    email,
    password,
    setOpenAuthModal,
    setUserData,
    setError,
    setLoader,
    setIsSignUp,
) => {
    console.log(email, password);
    setLoader(true);
    try {
        const response = await apiConnector('POST', auth.login, {
            email: email,
            password: password,
        });
        if (
            !response.data.successs &&
            response.data.errorCode === 'USER_NOT_FOUND'
        ) {
            setIsSignUp(true);
        }
        console.log('Login Response :: ', response);
        if (!response.data?.success) {
            throw Error(response.data?.message);
        }

        setUserData(response.data.user);
        setOpenAuthModal(false);
        setLoader(false);
        // Cookies.set("amf._login.token", response.data.token, {
        //     sameSite: "Strict",
        //     path: "/",
        //     secure: true,
        //     expires: new Date(Date.now() + 15 * 24 * 60 * 60 * 1000),
        // });
    } catch (error) {
        console.log(error);
        setLoader(false);
        setError({ message: error.message });
    }
};
const updateProfile = async (
    formData,
    setLoading,
    setError,
    setUserData,
    handleCloseSubMenu,
) => {
    setLoading(true);
    try {
        const response = await apiConnector(
            'POST',
            auth.updateProfile,
            formData,
        );

        // console.log(response);
        if (!response.data.success) {
            throw new Error(response.data.errorMessage || response.data.message || response.data.errorCode);
        }
        setError(null);
        setLoading(false);
        handleCloseSubMenu();
        setUserData(response.data.user);
    } catch (error) {
        setError({ message: error.message });
        setLoading(false);
    }
};
export { login, sendOTP, signUp, updateProfile, updateSignUpInfo };
